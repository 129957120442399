@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'CherieBomb Regular';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/public/fonts/CherieBomb-Regular.ttf);
  }
  @font-face {
    font-family: 'OilvareBase Regular';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/public/fonts/OilvareBase-Regular.otf);
  }
  @font-face {
    font-family: 'Gineva Regular';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/public/fonts/Gineva-Regular.otf);
  }
}

body {
  min-height: 100vh;
}