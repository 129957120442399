.Game {
  text-align: center;
}

.Game-header {
  background-color: #222;
  height: 50px;
  padding: 20px;
  color: white;
}

.Game-title {
  font-size: 1.5em;
}

.Game-status {
  padding: 20px;
}

.Game-intro {
  font-size: large;
}

.Card {
  display: inline-block;
  /* border-color: white; */
  border-width: 5px;
  border-style: solid;
  margin-bottom: 5px;
  margin-left: 5px;
  width: 100px;
}

.Matched {
  border-color: #55cc88;
}

.CardContainer {
  display: inline-block;
  max-width: 950px;
  margin: "20px auto";
}

img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;    
}